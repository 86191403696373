/* add appropriate colors to fb, twitter and google buttons */
.fb {
    background-color: #3B5998;
    color: white;
}

.twitter {
    background-color: #55ACEE;
    color: white;
}

.google {
    background-color: #dd4b39;
    color: white;
}

.line {
    background-color: #00C300;
    color: white;
}